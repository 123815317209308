import React, { ReactElement } from 'react';
import './Avatar.scss';

interface Props {
    name: string;
}

const Avatar = ({ name }: Props): ReactElement => {
    return <div className="doct-avatar">{name}</div>;
};

export default Avatar;
