import React, { ReactElement, ReactChild } from 'react';
import { Variant, FontWeightVariant, TextTransformVariant } from './Enum';

import { TypographyProps } from '../enums';

import './Typography.scss';

function Typography({
    variant,
    children,
    fontWeight,
    letterSpacing,
    textTransform,
    style,
    className,
    href,
    targetBlank = false,
    component: Component,
}: TypographyProps): ReactElement {
    const classes = [className];

    if (fontWeight === FontWeightVariant.Regular) classes.push('font-weight-regular');

    if (fontWeight === FontWeightVariant.Medium) classes.push('font-weight-medium');

    if (fontWeight === FontWeightVariant.Bold) classes.push('font-weight-bold');
    if (textTransform === TextTransformVariant.Uppercase) classes.push('text-uppercase');
    if (textTransform === TextTransformVariant.Normal) classes.push('text-normal');
    if (style === 'italic') classes.push('font-italic');
    if (Component) classes.push(variant);

    switch (variant) {
        case Variant.Subtitle1:
            classes.push('subtitle-1');
            break;
        case Variant.Subtitle2:
            classes.push('subtitle-2');
            break;
        case Variant.Subtitle3:
            classes.push('subtitle-3');
            break;
        case Variant.Body1:
            classes.push('body-1');
            break;
        case Variant.Body2:
            classes.push('body-2');
            break;
        case Variant.Body3:
            classes.push('body-3');
            break;
        case Variant.TextLabel1:
            classes.push('text-label-1');
            break;
        case Variant.TextLabel2:
            classes.push('text-label-2');
            break;
        case Variant.Caption1:
            classes.push('caption-1');
            break;
        case Variant.Caption2:
            classes.push('caption-2');
            break;
        case Variant.Input:
            classes.push('input-text');
            break;
        case Variant.List:
            classes.push('list-item');
            break;
        case Variant.Dropdown:
            classes.push('dropdown-item');
            break;
        case Variant.ResultCounter:
            classes.push('result-counter');
            break;
        case Variant.Overline:
            classes.push('overline');
            break;
        case Variant.Link1:
            classes.push('link-1');
            break;
        case Variant.Link2:
            classes.push('link-2');
            break;
        case Variant.TabItem:
            classes.push('tab-item');
            break;
        default:
            null;
    }

    const propsForElement = {
        style: { letterSpacing: `${letterSpacing}px` },
        className: classes.join(' ').trimStart().trimEnd(),
    };

    const ComponentFromProps = () => (
        <Component {...propsForElement}>
            <>{children}</>
        </Component>
    );

    if (Component) {
        return <ComponentFromProps />;
    }

    switch (variant) {
        case 'h1':
            return <h1 {...propsForElement}>{children}</h1>;
        case 'h2':
            return <h2 {...propsForElement}>{children}</h2>;
        case 'h3':
            return <h3 {...propsForElement}>{children}</h3>;
        case 'h4':
            return <h4 {...propsForElement}>{children}</h4>;
        case 'h5':
            return <h5 {...propsForElement}>{children}</h5>;
        case 'h6':
            return <h6 {...propsForElement}>{children}</h6>;
        case 'subtitle1':
        case 'subtitle2':
        case 'subtitle3':
        case 'body1':
        case 'body2':
        case 'body3':
        case 'textLabel1':
        case 'textLabel2':
            return <p {...propsForElement}>{children}</p>;
        case 'caption1':
        case 'caption2':
        case 'result-counter':
        case 'overline':
        case 'input':
            return <span {...propsForElement}>{children}</span>;

        case 'list':
        case 'dropdown':
            return <li {...propsForElement}>{children}</li>;
        case 'link1':
        case 'link2':
            return (
                <a className={classes.join(' ')} href={href} rel="noreferrer" target={targetBlank ? '_blank' : ''}>
                    {children}
                </a>
            );
        case 'tabItem':
            return (
                <a className={classes.join(' ')} href={href} rel="noreferrer" target={targetBlank ? '_blank' : ''}>
                    {children}
                </a>
            );
        default:
            return null;
    }
}

export default Typography;
