import React, { ReactElement } from 'react';
import Icon from '../Icons/Icons';

interface Props {
    rest?: unknown;
}
const DoctLogo = ({ ...rest }: Props): ReactElement => {
    return <Icon {...rest} name="docthubLogo" fill="#00a0c0" height="18" width="101.608" />;
};

export default DoctLogo;
