import React, { ReactElement } from 'react';

interface Props {
    children: JSX.Element;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    offset?: string;
    display?: string;
    className?: string;
}

function Col({ children, xs, sm, md, lg, xl, offset, display, className, ...rest }: Props): ReactElement {
    const classes = [className];

    if (xs) {
        classes.push(`col-${xs}`);
    }
    if (sm) {
        classes.push(`col-sm-${sm}`);
    }
    if (md) {
        classes.push(`col-md-${md}`);
    }
    if (lg) {
        classes.push(`col-lg-${lg}`);
    }
    if (xl) {
        classes.push(`col-xl-${xl}`);
    }
    if (offset) {
        classes.push(`${offset}`);
    }
    if (display) {
        classes.push(`${display}`);
    }

    return (
        <div className={classes.join(' ')} {...rest}>
            {children}
        </div>
    );
}

export default Col;
