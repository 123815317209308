import React, { ReactElement } from 'react';

import DoctIcon from '../Icons/Icons';
import { ButtonSize, ButtonType, ButtonVariant } from './enum';

import './IconButton.scss';

interface Props {
    variant?: 'contained' | 'outlined' | 'text';
    size?: string;
    icon?: string;
    disabled?: boolean;
    onButtonClickHandler?: () => void;
    type?:
        | 'primary'
        | 'secondary'
        | 'semantic-danger'
        | 'semantic-success'
        | 'semantic-warning'
        | 'semantic-info'
        | 'inverse';
}

export default function DoctButton({
    variant = 'contained',
    size = 'large',
    type = 'primary',
    icon,
    disabled,
    onButtonClickHandler,
}: Props): ReactElement {
    const className = ['doct-button doct-round-button'];

    // Button Type
    if (type === ButtonType.Primary && variant !== ButtonVariant.Outlined)
        className.push('bg-primary text-white doct-primary-button');
    else if (type === ButtonType.Secondary) className.push('text-grey-600 doct-secondary-button');
    else if (type === ButtonType.Inverse) className.push('text-grey-600 doct-inverse-button');
    else if (type === ButtonType.SemanticDanger) className.push('bg-danger text-white');
    else if (type === ButtonType.SemanticSuccess) className.push('bg-success text-white');
    else if (type === ButtonType.SemanticWarning) className.push('bg-warning text-white');
    else if (type === ButtonType.SemanticInfo) className.push('bg-info text-white');

    // Button Size
    if (size === ButtonSize.Small) className.push('doct-small-button');
    else if (size === ButtonSize.Medium) className.push('doct-medium-button');
    else className.push('doct-large-button');

    // Disabled
    if (disabled) className.push('disabled');

    // Button Variant
    if (variant === ButtonVariant.Contained) className.push('doct-contained-button');
    else if (variant === ButtonVariant.Text) className.push('doct-text-button');
    else className.push('doct-outlined-button');

    return (
        <button onClick={onButtonClickHandler} className={className.join(' ')} disabled={disabled}>
            <DoctIcon width="22" name={icon} />
        </button>
    );
}
