import React, { ReactElement } from 'react';
import Icon from '../Icons/Icons';

interface Props {
    rest?: unknown;
}
const DoctLogoRegTM = ({ ...rest }: Props): ReactElement => {
    return (
        <Icon {...rest} name="docthubLogoRegTM" fill="#00a0c0" height="18" width="127.085" viewBox="0 0 127.085 18" />
    );
};

export default DoctLogoRegTM;
